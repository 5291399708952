<template>
  <div class="calendar-filters">
    <div class="calendar-filters__inner d-flex align-center">
      <form-builder
        ref="formBuilder"
        v-model="valueModel"
        enable-cache
        remove-label-suffix
        class="flex-grow-1"
        :schema="schema"
        :initial-data="value"
      />
    </div>
    <div class="d-flex justify-end mt-10">
      <v-btn elevation="0" color="#EEEEEE" class="ms-4" @click="isOpenMoreFilters = true">
        <v-icon class="me-2">mdi-chevron-left</v-icon>
        {{ $t('button.show_more_filters') }}
      </v-btn>
    </div>

    <base-modal
      v-model="isOpenMoreFilters"
      :title="$t('calendar.filters')"
      transition="dialog-bottom-transition"
      max-width="400"
      content-class="filter-dialog"
      scrollable
    >
      <MobileSort
        :options.sync="optionsModel"
        :sort-list="sortList"
        :sort-by="sortBy"
        :menu-props="{ offsetY: true }"
        class="mb-5"
      />
      <form-builder
        ref="formBuilder"
        v-model="valueModel"
        enable-cache
        remove-label-suffix
        class="flex-grow-1"
        :schema="modalSchema"
        :initial-data="value"
      />
    </base-modal>
  </div>
</template>

<script>
// Components
import BaseModal from '@/components/BaseModal.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';
import MobileSort from '@/components/MobileSort/index.vue';

// Utils
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'Filters',

  components: {
    BaseModal,
    FormBuilder,
    MobileSort,
  },

  props: {
    value: { type: Object, required: true },
    schema: { type: Array, required: true },
    modalSchema: { type: Array, required: true },
    sortList: { type: Array, required: true },
    sortBy: { type: Object, default: () => ({}) },
    options: { type: Object, required: true },
  },

  data() {
    return {
      isOpenMoreFilters: false,
    };
  },

  computed: {
    optionsModel: createModelWrapper('options', 'update:options'),
    valueModel: createModelWrapper('value', 'input'),
  },
};
</script>

<style lang="scss">
.calendar-filters {
  .show-filters {
    &__icon {
      transition: transform 0.2s;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
}

.filter-dialog {
  margin: 0 !important;
  margin-left: auto !important;
  max-height: 100% !important;
  height: 100%;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;

    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-card {
    height: 100%;
  }
}
</style>
