<template>
  <div :class="$style.calendar">
    <div :class="$style.calendarLeft">
      <div :class="$style.calendarTitle" class="px-4 py-4">
        <span class="text-h5 font-weight-medium">Properties</span>
      </div>
      <CalendarList
        ref="unitsList"
        :class="$style.calendarList"
        :cell-height="cellHeight"
        :items="visibleUnits"
        :total-height="totalHeight"
        :offset-y="offsetY"
        :scroll-top="scrollTop"
        :style="{ height: `${viewportHeight}px` }"
        :show-days-without-rental="showDaysWithoutRental"
      />
    </div>
    <div :class="$style.calendarRight">
      <CalendarChartCalendar
        ref="calendar"
        :class="$style.calendarHeader"
        :dates="visibleDates"
        :months="visibleMonths"
        :cell-height="cellHeight"
        :cell-width="cellWidth"
        :total-width="totalWidth"
        :viewport-height="viewportHeight"
        :viewport-width="viewportWidth"
        :scroll-left="scrollLeft"
        :offset-x="offsetX"
        :first-width="firstCellWidth"
        :last-width="lastCellWidth"
      />
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
        :style="{ width: viewportWidth + 'px' }"
      ></v-progress-linear>
      <CalendarChartTimeline
        ref="timeline"
        :class="$style.calendarTimeline"
        :items="visibleUnits"
        :dates="visibleDates"
        :cell-height="cellHeight"
        :cell-width="cellWidth"
        :total-height="totalHeight"
        :total-width="totalWidth"
        :viewport-height="viewportHeight"
        :viewport-width="viewportWidth"
        :scroll-top="scrollTop"
        :scroll-left="scrollLeft"
        :offset-y="offsetY"
        :offset-x="offsetX"
        :first-width="firstCellWidth"
        :last-width="lastCellWidth"
        :disable-items="disableItems"
        @open-usage-info="$emit('open-usage-info', $event)"
        @open-modal-for-create="$emit('open-modal-for-create', $event)"
        @start-drag="startDrag"
        @start-resize="startResize"
      />
    </div>
  </div>
</template>

<script>
import {
  format,
  parseISO,
  formatISO,
  startOfDay,
  endOfDay,
  areIntervalsOverlapping,
  addDays,
  isAfter,
  subDays,
  isBefore,
  differenceInDays,
  differenceInCalendarDays,
  isSameDay,
} from 'date-fns';
import { clone } from '@/utils/clone';
import { translateShortDate } from '@/utils/dateFormatting';

import calendarService from '@/services/calendar';
import notificationService from '@/services/notification';

import CalendarList from './CalendarList.vue';
import CalendarChartCalendar from './CalendarChartCalendar.vue';
import CalendarChartTimeline from './CalendarChartTimeline.vue';

export default {
  name: 'Graph',

  components: {
    CalendarList,
    CalendarChartCalendar,
    CalendarChartTimeline,
  },

  provide() {
    return {
      normalizeBooking: this.normalizeBooking,
    };
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    units: {
      type: Array,
      default: () => [],
    },
    unitsCount: {
      type: Number,
      default: 0,
    },
    totalUnits: {
      type: Number,
      default: 0,
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
    cellMinWidth: {
      type: Number,
      default: 32.65,
    },
    showDaysWithoutRental: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      viewportHeight: 0,
      viewportWidth: 0,
      scrollTop: 0,
      scrollLeft: 0,
      bufferSize: 4,
      // firstCellWidth: 0,
      // lastCellWidth: 0,

      // Drag / resize booking
      isDrag: false,
      isResize: false,
      resizeSide: null,
      draggableBooking: null,
      draggableBookingClone: null,
      draggableBookingInitRentalPeriod: null,
      nearbyBookings: null,
      intervalId: null,
      timeoutId: null,
      boost: 0,
      disableItems: false,
      startDragDate: null,
    };
  },

  computed: {
    cellWidth() {
      const width = this.dates.length * this.cellMinWidth;
      return width < this.viewportWidth ? this.viewportWidth / this.dates.length : this.cellMinWidth;
    },
    startIndexY() {
      const startNode = Math.floor(this.scrollTop / this.cellHeight) - this.bufferSize;
      return Math.max(0, startNode);
    },
    startIndexX() {
      const startNode = Math.floor(this.scrollLeft / this.cellWidth);
      return Math.max(0, startNode);
    },
    offsetY() {
      return this.startIndexY * this.cellHeight;
    },
    offsetX() {
      return this.startIndexX * this.cellWidth;
    },
    totalHeight() {
      const height = this.units.length * this.cellHeight;
      return height < this.viewportHeight ? this.viewportHeight : height;
    },
    totalWidth() {
      const width = this.dates.length * this.cellMinWidth;
      return width < this.viewportWidth ? this.viewportWidth : width;
    },
    visibleNodeCountY() {
      const rowCount = Math.ceil(this.viewportHeight / this.cellHeight);
      let count = rowCount + 2 * this.bufferSize;
      count = Math.min(this.units.length - this.startIndexY, count);
      return count;
    },
    visibleNodeCountX() {
      const cellCount = Math.ceil((this.viewportWidth - this.firstCellWidth) / this.cellWidth);
      return cellCount + 1;
    },
    visibleUnits() {
      const rowCount = Math.ceil(this.viewportHeight / this.cellHeight);
      const visibleUnits = this.units.slice(this.startIndexY, this.startIndexY + this.visibleNodeCountY);
      while (visibleUnits.length < rowCount) {
        visibleUnits.push({
          id: `add-${visibleUnits.length}`,
        });
      }

      if (this.visibleDates?.length) {
        return visibleUnits.map(item => {
          return item.bookings
            ? {
                ...item,
                bookings: item.bookings.map(booking => this.normalizeBooking(booking, item.id)),
                archivedBookings: item.archivedBookings.map(booking => this.normalizeBooking(booking, item.id)),
              }
            : item;
        });
      }

      return visibleUnits;
    },
    visibleDates() {
      return this.dates.slice(this.startIndexX, this.startIndexX + this.visibleNodeCountX);
    },
    visibleMonths() {
      // Создаем объект для подсчета дней в каждом месяце
      const monthDayCounts = this.visibleDates.reduce((acc, date) => {
        const monthKey = format(new Date(date.id), 'yyyy-MM');
        acc[monthKey] = (acc[monthKey] || 0) + 1;
        return acc;
      }, {});

      // Получаем уникальные месяцы
      const uniqueMonths = Array.from(new Set(this.visibleDates.map(date => format(new Date(date.id), 'yyyy-MM'))));

      // Создаем массив месяцев с расчетом ширины
      const monthsArray = uniqueMonths.map((monthStr, index) => {
        const date = parseISO(`${monthStr}-01`);

        // Считаем дни в месяце, учитывая границы исходного диапазона
        const daysInMonth = monthDayCounts[monthStr];

        // Базовая ширина
        let width = daysInMonth * this.cellWidth;

        // Корректировка для первого и последнего месяца
        if (index === 0) {
          width -= this.cellWidth - this.firstCellWidth;
        }
        if (index === uniqueMonths.length - 1) {
          width -= this.cellWidth - this.lastCellWidth;
        }

        return {
          id: monthStr,
          label: translateShortDate(format(date, 'L-yyyy')),
          width,
        };
      });

      return monthsArray;
    },
    firstCellWidth() {
      if (this.viewportWidth >= this.totalWidth) {
        return this.cellWidth;
      }
      const localScrollProgress = (this.scrollLeft % this.cellWidth) / this.cellWidth;
      return this.cellWidth - this.cellWidth * localScrollProgress;
    },
    lastCellWidth() {
      if (this.scrollLeft + this.viewportWidth >= this.totalWidth) {
        return this.cellWidth;
      }

      const remainingWidth = (this.viewportWidth - this.firstCellWidth) % this.cellWidth;
      return remainingWidth === 0 ? this.cellWidth : remainingWidth;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getViewportSizes();
    });

    window.addEventListener('resize', this.getViewportSizes);

    this.$refs.unitsList.$el.addEventListener('scroll', e => this.handleScrollTop(e.target), { passive: true });
    this.$refs.calendar.$refs.scroller.addEventListener('scroll', e => this.handleScrollLeft(e.target), {
      passive: true,
    });
    this.$refs.timeline.$refs.scroller.addEventListener(
      'scroll',
      e => {
        this.handleScrollLeft(e.target);
        this.handleScrollTop(e.target);
      },
      { passive: true }
    );

    window.addEventListener('mousemove', e => {
      if (this.isResize) {
        this.isDrag = false;
        this.resizeBooking(e);
        return;
      }

      if (this.isDrag) this.moveBooking(e);
    });
    window.addEventListener('mouseup', e => (this.isResize || this.isDrag) && this.stopDrag(e));
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getViewportSizes);
  },

  methods: {
    getViewportSizes() {
      const viewport = this.$refs.timeline.$el || this.$refs.timeline;

      this.viewportHeight = viewport.clientHeight;
      this.viewportWidth = viewport.clientWidth;
    },
    handleScrollTop(block) {
      this.scrollTop = block.scrollTop;

      if (
        this.totalHeight <= this.scrollTop + this.viewportHeight + this.cellHeight &&
        this.unitsCount < this.totalUnits
      ) {
        this.$emit('update-calendar-list');
      }
    },
    handleScrollLeft(block) {
      this.scrollLeft = block.scrollLeft;
    },
    normalizeBooking(booking, unitId) {
      const startDate = startOfDay(new Date(booking.rentalPeriod.lower));
      const endDate = startOfDay(new Date(booking.rentalPeriod.upper));
      const startVisibleDate = this.visibleDates[0].value;
      const endVisibleDate = this.visibleDates[this.visibleDates.length - 1].value;

      const isVisible = areIntervalsOverlapping(
        { start: startVisibleDate, end: addDays(endVisibleDate, 1) },
        { start: startDate, end: endDate }
      );

      if (!isVisible) {
        return { ...booking, style: { display: 'none' } };
      }

      const isVisibleStartDate = isAfter(startDate, subDays(startVisibleDate, 1)); // начало аренды после видимой начальной даты календаря
      const isVisibleEndDate = isBefore(endDate, addDays(endVisibleDate, 1)); // окончание аренды до видимой последней даты календаря

      const margin = 2;
      const cellOffset = this.cellWidth / 2 + margin;

      const difStartDays = differenceInDays(startDate, startVisibleDate);
      const difStartToEndVisibleDays = differenceInDays(endVisibleDate, startDate);
      const difEndToStartVisibleDays = differenceInDays(endDate, startVisibleDate);

      const left = isVisibleStartDate
        ? `${difStartDays * this.cellWidth + cellOffset - (this.cellWidth - this.firstCellWidth)}px`
        : '0px';

      let width;
      // Если начало и конец в зоне видимости
      if (isVisibleStartDate && isVisibleEndDate) {
        width = differenceInDays(endDate, startDate) * this.cellWidth - margin * 2;
      }

      // Если только начало в зоне видимости
      if (isVisibleStartDate && !isVisibleEndDate) {
        width = (difStartToEndVisibleDays - 1) * this.cellWidth + cellOffset + this.lastCellWidth - margin;
      }

      // Если только конец в зоне видимости
      if (!isVisibleStartDate && isVisibleEndDate) {
        width =
          (difEndToStartVisibleDays + 1) * this.cellWidth -
          (this.cellWidth - this.firstCellWidth) -
          (this.cellWidth / 2 + margin);
      }

      // Если начало и конец за пределами календаря
      if (!isVisibleStartDate && !isVisibleEndDate) {
        width = this.viewportWidth;
      }

      const borderRadiusLeft = isVisibleStartDate ? '8px' : '0px';
      const borderRadiusRight = isVisibleEndDate ? '8px' : '0px';

      return {
        ...booking,
        unitId: booking.unitId || unitId,
        isVisibleStartDate,
        isVisibleEndDate,
        style: {
          left,
          width: width < 0 ? 0 : `${width}px`,
          'border-radius': `${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`,
        },
      };
    },

    addNewBooking(booking) {
      for (let i = 0; i < this.units.length; i += 1) {
        if (this.units[i].id === (booking.room?.id || booking.unit.id)) {
          this.units[i].bookings.push(booking);
          break;
        }
      }
    },

    startDrag({ evt, booking }) {
      this.isDrag = true;
      this.draggableBooking = booking;
      this.draggableBookingClone = clone(booking);

      const [unit] = this.units.filter(item => item.id === booking.unitId);
      this.nearbyBookings = [
        ...unit.bookings.filter(book => book.usageId !== booking.usageId),
        ...unit.archivedBookings,
      ];

      const { left } = this.$refs.timeline.$el.getBoundingClientRect();
      const mouseStartPosition = evt.clientX;
      const mousePos = mouseStartPosition - left;
      const daysCountFromStartToMouse = Math.floor((mousePos - this.firstCellWidth) / this.cellWidth + 1);
      this.startDragDate = addDays(this.visibleDates[0]?.value, daysCountFromStartToMouse);
    },

    startResize({ side, booking }) {
      this.isResize = true;
      this.draggableBooking = booking;
      this.draggableBookingClone = clone(booking);
      this.resizeSide = side;
    },

    async stopDrag() {
      this.isDrag = false;
      this.isResize = false;
      clearInterval(this.intervalId);
      clearTimeout(this.timeoutId);
      this.intervalId = null;
      this.timeoutId = null;
      this.boost = 0;

      document.body.style.cssText = `
        -webkit-user-select: null;
        -moz-user-select: null;
        -ms-user-select: null;
        cursor: null;
      `;

      if (
        !isSameDay(
          new Date(this.draggableBooking.rentalPeriod.lower),
          new Date(this.draggableBookingClone.rentalPeriod.lower)
        ) ||
        !isSameDay(
          new Date(this.draggableBooking.rentalPeriod.upper),
          new Date(this.draggableBookingClone.rentalPeriod.upper)
        ) ||
        this.draggableBooking.unitId !== this.draggableBookingClone.unitId
      ) {
        const bodyRequest = {
          unit: this.draggableBooking.unitId,
          rentalPeriod: {
            lower: format(new Date(this.draggableBooking.rentalPeriod.lower), 'yyyy-L-d'),
            upper: format(new Date(this.draggableBooking.rentalPeriod.upper), 'yyyy-L-d'),
          },
        };

        await calendarService
          .updateBooking(bodyRequest, this.draggableBooking.usageId)
          .then(() => {
            notificationService.success(this.$t('calendar.booking_is_changed'), 2000);
          })
          .catch(err => {
            if (err?.response?.data?.non_field_errors?.length) {
              this.$options.notificationItem = notificationService.error(err.response.data.non_field_errors[0]);
            }

            const [unit] = this.units.filter(item => item.id === this.draggableBooking.unitId);
            const [oldUnit] = this.units.filter(item => item.id === this.draggableBookingClone.unitId);

            if (unit.id !== oldUnit.id) {
              const booking = {
                ...this.draggableBooking,
                unitId: this.draggableBookingClone.unitId,
                rentalPeriod: this.draggableBookingClone.rentalPeriod,
              };

              unit.bookings = unit.bookings.filter(item => item.usageId !== this.draggableBooking.usageId);
              oldUnit.bookings.push(this.normalizeBooking(booking));
            } else {
              const [booking] = unit.bookings.filter(book => book.usageId === this.draggableBooking.usageId);
              booking.rentalPeriod = { ...this.draggableBookingClone.rentalPeriod };
            }
          });
      }

      this.disableItems = false;
      this.draggableBooking = null;
      this.draggableBookingClone = null;
      this.nearbyBookings = null;
    },

    moveBooking(evt) {
      const { left, top, width, height } = this.$refs.timeline.$el.getBoundingClientRect();
      const mouseIsOuterTop = evt.clientY < top;
      const mouseIsOuterBottom = evt.clientY > top + height;
      const mouseIsOuterRight = evt.clientX > left + width;
      const mouseIsOuterLeft = evt.clientX < left;

      this.disableItems = true;
      document.body.style.cssText = `
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: grabbing;
      `;

      let { date } = evt.target.dataset;
      const { unitId } = evt.target.dataset;

      if (mouseIsOuterRight) {
        this.scrollCalendar('right', evt);
      } else if (mouseIsOuterLeft) {
        this.scrollCalendar('left', evt);
      } else if (mouseIsOuterTop) {
        this.scrollCalendar('top', evt);
      } else if (mouseIsOuterBottom) {
        this.scrollCalendar('bottom', evt);
      } else {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.intervalId = null;
        this.timeoutId = null;
        this.boost = 0;
      }

      if (mouseIsOuterTop || mouseIsOuterBottom) {
        date = this.visibleDates[Math.floor((evt.clientX - left) / this.cellWidth)].id;
      }

      if (mouseIsOuterRight) {
        date = this.visibleDates[this.visibleDates.length - 1].value;
      }

      if (mouseIsOuterLeft) {
        date = this.visibleDates[0].value;
      }

      const diff = differenceInCalendarDays(new Date(date), this.startDragDate);

      const { rentalPeriod } = this.draggableBooking;

      if (Number(unitId) && this.draggableBooking.unitId !== Number(unitId) && !evt.target.closest('.--disable-row')) {
        const [unit] = this.units.filter(item => item.id === this.draggableBooking.unitId);
        const [newUnit] = this.units.filter(item => item.id === Number(unitId));
        const nearbyBookings = [
          ...newUnit.bookings.filter(book => book.usageId !== this.draggableBooking.usageId),
          ...newUnit.archivedBookings,
        ];

        let newRentalPeriod = {
          lower: new Date(rentalPeriod.lower),
          upper: new Date(rentalPeriod.upper),
        };

        if (diff > 0) {
          newRentalPeriod = {
            lower: addDays(new Date(rentalPeriod.lower), diff),
            upper: addDays(new Date(rentalPeriod.upper), diff),
          };
        }

        if (diff < 0) {
          newRentalPeriod = {
            lower: subDays(new Date(rentalPeriod.lower), Math.abs(diff)),
            upper: subDays(new Date(rentalPeriod.upper), Math.abs(diff)),
          };
        }

        if (!this.checkIntersections(newRentalPeriod, nearbyBookings)) {
          const booking = {
            ...this.draggableBooking,
            unitId: newUnit.id,
            rentalPeriod: newRentalPeriod,
          };

          unit.bookings = unit.bookings.filter(item => item.usageId !== this.draggableBooking.usageId);
          newUnit.bookings.push(this.normalizeBooking(booking));
          this.draggableBooking = newUnit.bookings[newUnit.bookings.length - 1];

          this.nearbyBookings = [
            ...newUnit.bookings.filter(book => book.usageId !== this.draggableBooking.usageId),
            ...newUnit.archivedBookings,
          ];
        }
      }

      if (diff > 0) {
        const newRentalPeriod = {
          lower: addDays(new Date(rentalPeriod.lower), diff),
          upper: addDays(new Date(rentalPeriod.upper), diff),
        };
        if (!this.checkIntersections(newRentalPeriod)) {
          rentalPeriod.lower = newRentalPeriod.lower;
          rentalPeriod.upper = newRentalPeriod.upper;
          this.startDragDate = new Date(date);
        }
      }

      if (diff < 0) {
        const newRentalPeriod = {
          lower: subDays(new Date(rentalPeriod.lower), Math.abs(diff)),
          upper: subDays(new Date(rentalPeriod.upper), Math.abs(diff)),
        };
        if (!this.checkIntersections(newRentalPeriod)) {
          rentalPeriod.lower = newRentalPeriod.lower;
          rentalPeriod.upper = newRentalPeriod.upper;
          this.startDragDate = new Date(date);
        }
      }
    },

    resizeBooking(evt) {
      const { left, width } = this.$refs.timeline.$el.getBoundingClientRect();
      let { date } = evt.target.dataset;
      const mouseIsOuterRight = evt.clientX > left + width;
      const mouseIsOuterLeft = evt.clientX < left;
      this.disableItems = true;

      document.body.style.cssText = `
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: col-resize;
      `;

      if (mouseIsOuterRight) {
        this.scrollCalendar('right', evt);
      } else if (mouseIsOuterLeft) {
        this.scrollCalendar('left', evt);
      } else {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.intervalId = null;
        this.timeoutId = null;
        this.boost = 0;
      }

      if (mouseIsOuterRight) {
        date = this.visibleDates[this.visibleDates.length - 1].value;
      }

      if (mouseIsOuterLeft) {
        date = this.visibleDates[0].value;
      }

      if (!date) return;

      const { rentalPeriod } = this.draggableBooking;

      const newRentalPeriod = {
        lower: rentalPeriod.lower,
        upper: rentalPeriod.upper,
      };

      if (this.resizeSide === 'left') {
        const lower = formatISO(new Date(endOfDay(new Date(date))));
        newRentalPeriod.lower = lower || newRentalPeriod.lower;
      }

      if (this.resizeSide === 'right') {
        const upper = formatISO(new Date(startOfDay(new Date(date))));
        newRentalPeriod.upper = upper || newRentalPeriod.upper;
      }

      if (isBefore(new Date(newRentalPeriod.upper), new Date(newRentalPeriod.lower))) {
        return;
      }

      if (!this.checkIntersections(newRentalPeriod)) {
        rentalPeriod.lower = newRentalPeriod.lower;
        rentalPeriod.upper = newRentalPeriod.upper;
      }
    },

    checkIntersections(period, bookings) {
      const nearbyBookings = bookings || this.nearbyBookings;

      for (let i = 0; i < nearbyBookings?.length; i += 1) {
        const rentalPeriod = { ...nearbyBookings[i].rentalPeriod };
        const isOverlapping = areIntervalsOverlapping(
          { start: new Date(period.lower), end: new Date(period.upper) },
          { start: new Date(rentalPeriod.lower), end: new Date(rentalPeriod.upper) }
        );

        if (isOverlapping) {
          return true;
        }
      }
      return false;
    },

    scrollCalendar(direction, evt) {
      const { width, height } = this.$refs.timeline.$el.getBoundingClientRect();

      if (!this.intervalId && !this.timeoutId) {
        this.intervalId = setInterval(() => {
          if (direction === 'right' && this.scrollLeft + width < this.totalWidth) {
            this.scrollLeft += 5 + this.boost;
          } else if (direction === 'left' && this.scrollLeft > 0) {
            this.scrollLeft -= 5 + this.boost;
          } else if (direction === 'top' && this.scrollTop > 0) {
            this.scrollTop -= 5 + this.boost;
          } else if (direction === 'bottom' && this.scrollTop + height < this.totalHeight) {
            this.scrollTop += 5 + this.boost;
          }
          if (this.isResize) {
            this.resizeBooking(evt);
          }
          if (this.isDrag) {
            this.moveBooking(evt);
          }
        }, 5);

        this.timeoutId = setTimeout(() => {
          this.boost = 10;
        }, 500);
      }
    },
  },

  cancelSource: null,
  notificationItem: null,
};
</script>

<style lang="scss" module>
.calendar {
  position: relative;
  min-height: 640px;
  height: calc(100vh - 340px);
  display: flex;
  overflow: hidden;
  font-size: 14px;
  background: #ffffff;
  border-bottom: 1px solid #e6ebff;

  // * {
  //   box-sizing: border-box;
  // }

  // &__chart {
  //   flex: 1 1 100%;
  //   overflow: hidden;
  //   position: relative;
  // }

  // &__chart-scrollbar {
  //   overflow: hidden;
  //   position: absolute;

  //   &.--vertical {
  //     top: 116px;
  //     bottom: 15px;
  //     right: 0;
  //     width: 15px;
  //     height: calc(100% - 116px - 15px);

  //     .calendar__chart-scrollbar-inner {
  //       width: 100%;
  //       height: 100px;
  //     }
  //   }

  //   &.--horizontal {
  //     bottom: 0;
  //     right: 15px;
  //     width: calc(100% - 15px);
  //     height: 15px;

  //     .calendar__chart-scrollbar-inner {
  //       width: 100px;
  //       height: 100%;
  //     }
  //   }
  // }

  // &__chart-scrollbar-inner {
  //   background: rgba(0, 0, 0, 0.16);
  //   border-radius: 4px;
  //   position: absolute;
  //   touch-action: none;
  //   cursor: grab;
  // }
}

.calendarLeft {
  flex: 0 0 207px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e6ebff;
}

.calendarTitle {
  flex: 0 0 68px;
  height: 68px;
  margin-top: 48px;
  border-top: 1px solid #e6ebff;
  border-bottom: 1px solid #e6ebff;
}

.calendarList {
  flex: 0 0 auto;
}
.calendarRight {
  flex: 1 1 100%;
  height: 100%;
  overflow: hidden;
}
</style>
