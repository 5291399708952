<template>
  <div ref="scroller" class="calendar__chart-timeline">
    <div class="calendar__chart-timeline-inner" :style="{ height: totalHeight + 'px', width: totalWidth + 'px' }">
      <div
        ref="timelineInnerGrid"
        class="calendar__chart-timeline-grid"
        :style="{ transform: `translate(${scrollLeft}px, ${offsetY}px)` }"
      >
        <div class="calendar__chart-timeline-grid-rows">
          <div
            v-for="(item, index) in items"
            :key="item.id"
            class="calendar__chart-timeline-grid-row"
            :class="{ '--disable-row': isHasRooms(item) }"
            :style="{ height: index ? cellHeight + 'px' : cellHeight + 1 + 'px' }"
            @mousedown="startDraw($event, item)"
          >
            <div
              v-for="(date, dateIndex) in dates"
              :key="date.id"
              class="calendar__chart-timeline-grid-row-cell"
              :style="{
                width: getCellWidth(dateIndex),
              }"
              :data-date="date.id"
              :data-unit-id="item.id"
            />
          </div>
        </div>
        <div v-if="todayLinePosition" class="today-line" :style="{ left: `${todayLinePosition}px` }"></div>
        <calendar-chart-timeline-grid
          :items="items"
          :cell-height="cellHeight"
          :cell-width="cellWidth"
          :scroll-left="scrollLeft"
          :last-date="dates[dates.length - 1]?.value"
          :first-date="dates[0]?.value"
          :first-width="firstWidth"
          :ghost-booking="ghostBooking"
          :ghost-new-book="ghostNewBook"
          :disable-items="disableItems"
          @open-usage-info="$emit('open-usage-info', $event)"
          @start-drag="$emit('start-drag', $event)"
          @start-resize="$emit('start-resize', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { format, isSameDay, startOfDay, endOfDay, areIntervalsOverlapping } from 'date-fns';
import CalendarChartTimelineGrid from './CalendarChartTimelineGrid.vue';

export default {
  name: 'CalendarChartTimeline',

  components: {
    CalendarChartTimelineGrid,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    currentDate: {
      type: Date,
      default: () => new Date(),
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
    cellWidth: {
      type: Number,
      default: 32.65,
    },
    viewportHeight: {
      type: Number,
      default: 0,
    },
    viewportWidth: {
      type: Number,
      default: 0,
    },
    totalHeight: {
      type: Number,
      default: 0,
    },
    totalWidth: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    scrollLeft: {
      type: Number,
      default: 0,
    },
    firstWidth: {
      type: Number,
      required: true,
    },
    lastWidth: {
      type: Number,
      required: true,
    },
    todayPositionLeft: {
      type: Number,
      default: null,
    },
    ghostBooking: {
      type: Object,
      default: null,
    },
    disableItems: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDraw: false,
      startDrawDate: null,
      endDrawDate: null,
      drawUnit: null,
      drawUnitBookings: null,
      intervalId: null,
      timeoutId: null,
      boost: 0,
      ghostNewBook: null,
    };
  },

  computed: {
    todayLinePosition() {
      const today = startOfDay(new Date());
      const todayIndex = this.dates.findIndex(date => isSameDay(date.value, today));

      if (todayIndex === -1) return null;
      const position = this.firstWidth + (todayIndex - 1) * this.cellWidth + this.cellWidth / 2 - 1;
      return position >= 0 && position <= this.viewportWidth ? position : null;
    },
  },

  watch: {
    scrollTop(value) {
      if (this.$refs.scroller.scrollTop === value) return;
      this.$nextTick(() => {
        this.$refs.scroller.scrollTop = value;
      });
    },
    scrollLeft(value) {
      if (value === this.$refs.scroller.scrollLeft) return;
      this.$nextTick(() => {
        this.$refs.scroller.scrollLeft = value;
      });
    },
  },

  mounted() {
    window.addEventListener('mouseup', evt => this.isDraw && this.stopDraw(evt));
    window.addEventListener('mousemove', evt => this.isDraw && this.draw(evt));
  },

  methods: {
    getCellWidth(dateIndex) {
      if (dateIndex === 0) {
        return `${this.firstWidth}px`;
      }

      if (dateIndex === this.dates.length - 1) {
        return `${this.lastWidth}px`;
      }

      return `${this.cellWidth}px`;
    },
    isHasRooms(unit) {
      if (!unit) return false;
      return unit.rooms?.length;
    },

    startDraw(evt, unit) {
      this.drawUnit = unit;
      this.drawUnitBookings = [...unit.bookings, ...unit.archivedBookings];
      const { date } = evt.target.dataset;
      if (!date || evt.target.closest('.--disable-row')) return;
      this.isDraw = true;
      this.startDrawDate = date;

      document.body.style.cssText = `
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      `;
    },

    stopDraw() {
      if (this.ghostNewBook) {
        this.$emit('open-modal-for-create', this.ghostNewBook);
      }

      this.isDraw = false;
      this.startDrawDate = null;
      this.endDrawDate = null;
      this.drawUnit = null;
      this.drawUnitBookings = null;
      clearInterval(this.intervalId);
      clearTimeout(this.timeoutId);
      this.intervalId = null;
      this.timeoutId = null;
      this.boost = 0;
      this.ghostNewBook = null;

      document.body.style.cssText = `
        -webkit-user-select: null;
        -moz-user-select: null;
        -ms-user-select: null;
      `;
    },

    draw(evt) {
      let anotherDate;
      const { date } = evt.target.dataset;
      const { left, width } = this.$el.getBoundingClientRect();
      const mouseIsOuterRight = evt.clientX > left + width;
      const mouseIsOuterLeft = evt.clientX < left;

      if (mouseIsOuterRight) {
        this.scrollCalendar(true, evt);
      } else if (mouseIsOuterLeft) {
        this.scrollCalendar(false, evt);
      } else {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.intervalId = null;
        this.timeoutId = null;
        this.boost = 0;
      }

      if (mouseIsOuterRight) {
        anotherDate = this.dates[this.dates.length - 1].value;
      }

      if (mouseIsOuterLeft) {
        anotherDate = this.dates[0].value;
      }

      if (!date && !anotherDate) return;

      this.endDrawDate = date || anotherDate;

      let startDate = new Date(this.startDrawDate);
      let endDate = new Date(this.endDrawDate);

      if (startDate > endDate) {
        // Если конечная дата раньше начальной, меняем их местами
        [startDate, endDate] = [endDate, startDate];
      }

      const rentalPeriod = {
        lower: format(startDate, 'yyyy-L-dd'),
        upper: format(endDate, 'yyyy-L-dd'),
      };

      if (
        !isSameDay(new Date(this.startDrawDate), new Date(this.endDrawDate)) &&
        !this.checkIntersections(rentalPeriod)
      ) {
        this.ghostNewBook = {
          rentalPeriod,
          unitId: this.drawUnit.id,
          unit: {
            name: this.drawUnit.name,
            id: this.drawUnit.id,
          },
          unitType: this.drawUnit.type,
          usageId: 'new-usage',
        };
      }
    },

    scrollCalendar(right, evt) {
      if (!this.intervalId && !this.timeoutId) {
        this.intervalId = setInterval(() => {
          if (right) {
            this.$el.scrollLeft += 5 + this.boost;
          } else {
            this.$el.scrollLeft -= 5 + this.boost;
          }
          this.draw(evt);
        }, 5);

        this.timeoutId = setTimeout(() => {
          this.boost = 10;
        }, 500);
      }
    },

    checkIntersections(period) {
      const newInterval = {
        start: endOfDay(new Date(period.lower)),
        end: startOfDay(new Date(period.upper)),
      };

      return this.drawUnitBookings.some(booking => {
        const bookingInterval = {
          start: endOfDay(new Date(booking.rentalPeriod.lower)),
          end: startOfDay(new Date(booking.rentalPeriod.upper)),
        };

        return areIntervalsOverlapping(newInterval, bookingInterval, { inclusive: true });
      });
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__chart-timeline {
    position: relative;
    height: calc(100% - 116px);
    // border-right: 1px solid #e6ebff;
    overflow: auto;
  }

  &__chart-timeline-inner {
    overflow: hidden;
  }

  &__chart-timeline-grid {
    overflow: hidden;
    display: inline-block;
  }

  &__chart-timeline-grid-bookings {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  &__chart-timeline-grid-row {
    display: flex;
    text-align: center;
    user-select: none;
    pointer-events: all;
    overflow: hidden;
    width: 100%;

    &.--disable-row {
      background: #f5f5f5;

      .calendar__chart-timeline-grid-row-cell {
        // pointer-events: none;
      }
    }
  }

  &__chart-timeline-grid-row-cell {
    border-right: 1px solid #e6ebff;
    border-bottom: 1px solid #e6ebff;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.today-line {
  position: absolute;
  top: -1px;
  bottom: 0;
  width: 1px;
  background: #002fff;
  z-index: 1;
  pointer-events: none;
}
</style>
