import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  CLIENTS: 'clients',
  PAYMENT_TYPE: 'paymentTypes',
};

export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.MANY_CALENDAR_PROJECTS,
        label: 'label.projects',
        prop: dependentProperties.PROJECTS,
        isCalendar: true,
        payload: [
          {
            param: 'buildings',
            from: [dependentProperties.BUILDINGS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.RESET,
            to: dependentProperties.BUILDINGS,
          },
        ],
      },
      {
        type: schemaItemTypes.MANY_CALENDAR_BUILDINGS,
        label: 'label.buildings',
        prop: dependentProperties.BUILDINGS,
        isCalendar: true,
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'units',
            from: [dependentProperties.UNITS],
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
            onlyEffect: true,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.AUTOCOMPLETE_MANY,
            to: dependentProperties.PROJECTS,
          },
          {
            type: onUpdateEffects.RESET,
            to: dependentProperties.UNITS,
          },
        ],
      },
      {
        type: schemaItemTypes.MANY_CALENDAR_UNITS,
        label: 'label.units',
        prop: dependentProperties.UNITS,
        isCalendar: true,
        payload: [
          {
            param: 'projects',
            from: [dependentProperties.PROJECTS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
          {
            param: 'buildings',
            from: [dependentProperties.BUILDINGS],
            cache: true,
            serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.AUTOCOMPLETE_MANY,
            to: dependentProperties.BUILDINGS,
          },
        ],
      },
      {
        type: schemaItemTypes.CALENDAR_DATE,
        label: 'label.period',
        prop: 'period',
        multiple: true,
        size: 4,
      },
    ],
  },
];

export const modalSchema = [
  {
    type: schemaItemTypes.UNIT_TYPE,
    label: 'label.unit_type',
    prop: 'unitType',
    isCalendar: true,
    size: 3,
  },
  {
    type: schemaItemTypes.AMOUNT_RANGE,
    label: 'label.rental_price',
    prop: 'amountGoal',
    isCalendar: true,
    size: 3,
  },
];

export { schema as default };
