<template>
  <div ref="booking" class="booking-item--ghost" :style="booking.style">
    <span class="client-name">{{ clientName || selectedDates }}</span>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { monthsTanslations } from '@/constants/translations';

export default {
  name: 'BookingItemGhost',

  props: {
    booking: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fixedWidth() {
      return Number(parseFloat(this.booking.style?.width).toFixed()) - 10;
    },
    clientName() {
      if (!this.booking.firstName && !this.booking.lastName) return null;
      const lettersWidth = 10;

      const firstNameLength = this.booking.firstName?.length * lettersWidth;
      const lastNameLength = this.booking.lastName?.length * lettersWidth;

      if (firstNameLength + lastNameLength < this.fixedWidth) {
        return `${this.booking.firstName} ${this.booking.lastName}`;
      }

      if (firstNameLength + lettersWidth < this.fixedWidth) {
        return `${this.booking.firstName} ${this.booking.lastName && `${this.booking.lastName[0]}.`}`;
      }

      if (lettersWidth + lettersWidth < this.fixedWidth) {
        return `${this.booking.firstName[0]}. ${this.booking.lastName && `${this.booking.lastName[0]}.`}`;
      }

      return `${this.booking.firstName[0]}.`;
    },

    selectedDates() {
      const startDateText = `${this.$t(
        monthsTanslations[format(new Date(this.booking.rentalPeriod.lower), 'L') - 1]
      )} ${format(new Date(this.booking.rentalPeriod.lower), 'dd')}`;

      const endDateText = `${this.$t(
        monthsTanslations[format(new Date(this.booking.rentalPeriod.upper), 'L') - 1]
      )} ${format(new Date(this.booking.rentalPeriod.upper), 'dd')}`;

      return `${startDateText} - ${endDateText}`;
    },
  },
};
</script>

<style lang="scss">
.booking-item--ghost {
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  transform: translate(0, -50%);
  height: calc(100% - 20px);
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(106, 137, 239, 0.3);
  color: #002fff;
  // transition: width 0.2s;

  .client-name {
    pointer-events: none;
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
