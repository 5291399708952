<template>
  <base-modal v-model="isOpen" content-class="base-dialog" :title="title" @close="close">
    <template v-if="showCreatingClient" #title>
      <div class="d-flex align-center">
        <v-btn icon color="primary" class="mr-2" @click="openDetailed">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ title }}
      </div>
    </template>
    <template v-if="showCreatingBooking">
      <form-builder
        ref="bookingFormBuilder"
        v-model="bookingData"
        :initial-data="initialData"
        :schema="$options.bookingSchema"
        @submit="saveBooking"
        @event-from-field="eventFromField"
      >
        <template #footer="{ valid }">
          <v-btn :disabled="!valid" :loading="submitLoading" type="submit" elevation="0" color="primary" class="mt-2">
            {{ $t('button.book') }}
          </v-btn>
        </template>
      </form-builder>
    </template>
    <template v-if="showCreatingClient">
      <form-builder
        ref="newClientFormBuilder"
        :initial-data="clientData"
        :schema="$options.clientSchema"
        @submit="addClient"
      >
        <template #footer="{ valid }">
          <v-btn :disabled="!valid" :loading="submitLoading" type="submit" elevation="0" color="primary" class="mt-2">
            {{ $t('button.add') }}
          </v-btn>
        </template>
      </form-builder>
    </template>
  </base-modal>
</template>

<script>
// Plugins
import { format } from 'date-fns';

// Components
import BaseModal from '@/components/BaseModal.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Schema
import { bookingSchema, clientSchema } from '@/schemas/booking.schema';

// Models
import { createModelData } from '@/schemas/createModelData';

// Service
import calendarService from '@/services/calendar';
import usersService from '@/services/users';
import notificationService from '@/services/notification';

import { MODAL_CREATING_BOOKING, MODAL_CREATING_CLIENT } from '@/constants/calendar';

export default {
  name: 'CreateBookingModal',

  components: {
    BaseModal,
    FormBuilder,
  },

  props: {
    booking: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isOpen: false,
      isLoading: false,
      submitLoading: false,
      showWindow: MODAL_CREATING_BOOKING,
      sendEmailIfNewClient: false,
      bookingData: createModelData(this.$options.bookingSchema),
      initialData: createModelData(this.$options.bookingSchema),
      clientData: createModelData(this.$options.clientSchema),
    };
  },

  computed: {
    title() {
      return this.showWindow === MODAL_CREATING_BOOKING
        ? this.$t('calendar.book_apartment')
        : this.$t('calendar.add_client');
    },

    showCreatingBooking() {
      return this.showWindow === MODAL_CREATING_BOOKING;
    },

    showCreatingClient() {
      return this.showWindow === MODAL_CREATING_CLIENT;
    },
  },

  watch: {
    bookingData: {
      deep: true,
      handler(newData, oldData) {
        if (newData.client?.id !== oldData.client?.id && newData.client?.id !== 'new-client') {
          this.bookingData.passport = newData.client?.passport || '';
          this.bookingData.idNumber = newData.client?.idNumber || '';
          this.bookingData.country = newData.client?.country;
          this.bookingData.phone = newData.client?.phone || '';
          this.bookingData.email = newData.client?.email;
        }
      },
    },
    booking: {
      deep: true,
      handler(newData) {
        if (!newData.rentalPeriod) return;
        this.bookingData = {
          ...this.bookingData,
          ...newData,
          bookingDateFirst: newData.rentalPeriod.lower,
          bookingDateLast: newData.rentalPeriod.upper,
        };

        this.initialData = {
          ...this.initialData,
          ...newData,
          bookingDateFirst: newData.rentalPeriod.lower,
          bookingDateLast: newData.rentalPeriod.upper,
        };
      },
    },
  },

  methods: {
    async open() {
      this.isOpen = true;
    },

    openDetailed() {
      this.showWindow = MODAL_CREATING_BOOKING;
    },

    openAddingClient() {
      this.showWindow = MODAL_CREATING_CLIENT;
    },

    async saveBooking(data) {
      this.submitLoading = true;

      const clientData =
        data.client?.id === 'new-client'
          ? {
              firstName: data.client.firstName,
              lastName: data.client.lastName,
              passport: data.passport || data.passport,
              idNumber: data.idNumber || data.idNumber,
              country: data.country?.value || data.country?.value,
              phone: data.phone || data.phone,
              email: data.email,
              isSendPasswordWhenCreating: this.sendEmailIfNewClient,
            }
          : undefined;

      const bodyRequest = {
        client: data.client?.id === 'new-client' ? null : data.client?.id,
        clientData,
        unit: data.room?.id || data.unit.id,
        rentalPeriod: {
          lower: format(new Date(data.bookingDateFirst), 'yyyy-L-d'),
          upper: format(new Date(data.bookingDateLast), 'yyyy-L-d'),
        },
      };

      if (data.client?.id !== 'new-client') {
        const clientBodyRequest = {
          firstName: data.client.firstName,
          lastName: data.client.lastName,
          passport: data.passport,
          idNumber: data.idNumber,
          country: data.country?.value,
          phone: data.phone,
          email: data.email,
        };

        await usersService
          .partialUpdateClientById(clientBodyRequest, data.client.id)
          .then(() => {
            this.createBooking(bodyRequest);
          })
          .catch(err => {
            if (err?.response?.data?.non_field_errors?.length) {
              this.$options.notificationItem = notificationService.error(err.response.data.non_field_errors[0]);
            }
          });
      } else {
        this.createBooking(bodyRequest, true);
      }
    },

    createBooking(bodyRequest, isNewClient) {
      calendarService
        .createBooking(bodyRequest)
        .then(res => {
          if (isNewClient) {
            notificationService.success(this.$t('calendar.client_created'), 2000);
          }
          notificationService.success(this.$t('calendar.apartment_booked'), 2000);
          this.$emit('add-new-booking', res);
          this.close();
          this.$refs.bookingFormBuilder?.reset();
          this.$refs.newClientFormBuilder?.reset();
        })
        .catch(err => {
          if (err?.response?.data?.non_field_errors?.length) {
            this.$options.notificationItem = notificationService.error(err.response.data.non_field_errors[0]);
            return;
          }
          if (err?.response?.data?.length) {
            this.$options.notificationItem = notificationService.error(err.response.data[0]);
            return;
          }

          if (err?.response?.data) {
            Object.values(err.response.data).forEach(error => {
              if (Array.isArray(error)) {
                error.forEach(subError => {
                  this.$options.notificationItem = notificationService.error(subError);
                });
                return;
              }
              this.$options.notificationItem = notificationService.error(error);
            });
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },

    addClient(client) {
      this.bookingData.client = {
        ...client,
        id: 'new-client',
      };

      this.bookingData.client.lastName = client.lastName || '';
      this.bookingData.passport = client.passport || '';
      this.bookingData.idNumber = client.idNumber || '';
      this.bookingData.country = client.country;
      this.bookingData.phone = client.phone || '';
      this.bookingData.email = client.email;
      this.sendEmailIfNewClient = client.isSendPasswordWhenCreating || false;

      this.openDetailed();
    },

    eventFromField(type) {
      if (type === 'add-client') {
        this.openAddingClient();
      }
    },

    close() {
      this.isOpen = false;
      this.showWindow = MODAL_CREATING_BOOKING;
    },
  },
  notificationItem: null,
  bookingSchema,
  clientSchema,
};
</script>
